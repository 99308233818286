import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading"
// import { Link } from "react-router-dom";
import Queries from "./queries";
import 'reactjs-popup/dist/index.css';
import Constants from "../constants";
import Session from "./Session";



function Gallery(props) {
  
  const location = useLocation()
  //const Special = location.pathname.length > 1 ? Queries.getBooks() : Special
  const cat      = getCat(location.pathname)
  var vars       = {variables: {sort: cat.toLowerCase().endsWith("sort")}}
  var Special    = Queries.getBooks()

  if (location.pathname.indexOf("!") >= 0) {
    Special     = Queries.booksByAuthor()
    vars["variables"]["author"] = cat
  }
  else if (! ["gallery", "sort"].includes(cat.toLowerCase())) {
    Special = Queries.readGenred()
    vars["variables"]["category"] = cat
  } 
  else {
    vars["variables"]["max"] = "25";
  }
 
  //const {loading, error, data} = useQuery(Special, vars)

  function getCat( path ) {
    return path.length > 1 && path.startsWith("/")? 
    path.split("/").pop().split("!").pop() : "" 
  }

  // if (loading) return (
  //   <div className="specialGenre">
  //       <h2 className="center">Your Gallery</h2>
  //       <ReactLoading type="bars" height="50px" width="50px" color="#dedede"/>
  //   </div>
  //  )

  // if(error) {

  //   const message = error.name === "ApolloError" ? "Connection Problem" : "Data Load Failed."

  //   return (
  //   <div className="specialGenre">
  //       <h2 className="center">Your Gallery</h2>
  //       <div>{message}</div>
  //   </div>
  //   )
  // }

  const images = Session.getUserKey("photos")//(data.books || data.getFilteredBooks || data.booksByUser).filter(book=>Session.canView(book, true, true))
  
  return (
    <div className="specialGenre">
        <h2 className="center">Your Images  
        ({images.length})
        </h2>
        
            {
                images.length > 0 ? 
                images.map((image, i) => (
                  <div className="shop-card gall" key={i}>
                      {/* <Link to={{pathname: "/read"}} 
                      state= {{file: url}} 
                      title={"Read " + name + " By " + user.name}
                      style={{display: "block"}}> */}
                      {/* <Link to="/read" title={"Read " + name + " By " + author.name} 
                      style={{display: "block"}}
                      state={{file: url}}> */}
                      <img src={Constants.images + "/" + image} width="100%" height="100%" alt="&nbsp; User Profile"/>
                      {/* </Link>  */}
                    </div>
                )) : (<div className="center">You haven't uploaded any image yet.</div>)
            }
            
            {images.length > 0? (<div className="spacer"></div>) : ("") }

    </div>
  );
}

// Export it
export default Gallery;
