import axios from "axios";
import Constants from "../constants";
import $ from "jquery"

class Session {

    static user = {}
    static store= sessionStorage;
    static persistence = localStorage

    static addFavorite(book) {
        if (!Constants.isObject(book) || !book.hasOwnProperty("name") || !Boolean(book.name)) return false;
        var favorites = this.getFavorites()

        book.time = new Date();
        

        if (favorites.length >= 5) {
            delete favorites[0];
        }
            
        favorites = favorites.filter((_book) => _book !== null && _book.url !== book.url )
        favorites.push(book);
        
        this.persistence.setItem("favorite", JSON.stringify(favorites))
        
    }

    static getFavorites() {
        var books = this.persistence.hasOwnProperty("favorite")? 
        JSON.parse(this.persistence.getItem("favorite")) : [];

        return Array.isArray(books) ? books.filter(book => book.hasOwnProperty("name") && book.name !== "") : [] 
    }

    static has(key) {
        return this.store.hasOwnProperty(key)
    }

    static setUser(user, persist = false) {
        if (persist)
            this.persistence.setItem("user", typeof user === "string"? user: JSON.stringify(user) )
        else 
            this.store.setItem("user", typeof user === "string"? user: JSON.stringify(user) )
    }


    static getUser() {

        var user;
        if ( localStorage.hasOwnProperty("user") && localStorage.getItem("user").trim() !== "{}")
            user = JSON.parse(localStorage.getItem("user"))
        else if (sessionStorage.hasOwnProperty("user"))
            user = JSON.parse(sessionStorage.getItem("user"))
        else user = {};

        return user
    }

    static userID() {
        return this.getUserKey("_id")
    }

    static setRead(to) {
        this.persistence.setItem("read", to)
    }

    static loggedIn() {
        var user = this.getUser();
        
        return typeof user === "object" ? Object.keys(user).includes("email") : false;
    }

    static read() {
        return !this.persistence.hasOwnProperty("read")? true : this.persistence.getItem("read") === "true"
    }

    static register(fm) {
        if( !fm) fm = document.getElementById("regform")    
        
        var form = new FormData(fm);

        axios.post(Constants.api +"/register", form, {headers: {"Content-Type": "application/json"}})
        .then(resp => {
            if ( Object.keys(resp.data).includes("user") )
                $("#reginfo").html("Registeration successful, <a href='/' class='link'>Log in</a>?")
            else
                $("#reginfo").html("<span class='danger'>Registeration failed, try again.</span>")
            
        })
    }

    static async login(username, password, callback = null, _data = "", persist = false) {
        var 
        form = new FormData();
        form.set("username", username)
        form.set("password", password)
        //$("#lognote").text("Logging in...")
        $("#lognote").show()
        axios.post(Constants.auth, form, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(resp => {

            const data = resp.data;
            //console.log(data)

            if((typeof data === "object") && 
            Object.keys(data).includes("user") && 
            ((typeof data.user) === "object")) {
                if( data.user && Object.keys(data.user).includes("email") ) {
                    this.setUser(data.user, persist)
                    this.isLoggedIn = true

                    
                    if(callback) callback(_data)

                }
                else {
                    
                    $("#lognote").html("<span class='danger'>Incorrect username or password</span>")
                }
                
            }
        }).catch(e => {
            $("#lognote").html("<span class='danger'>A Problem occured, please check your connection.</span>")
        })
        
    }

    static authorized(book) {
        if( typeof book !== "object") return false;
        if (book) return book.user.id === this.userID() || book.category.name === "General"
    }

    static canView(book, images = false, serious = false) {
        if (typeof book !== "object" || !Object.keys(book).includes("user")) return false;

        if (serious && book.user.id !== this.userID() ) return false;
        return images? Constants.isImage(book.url) && this.authorized(book):
            !Constants.isImage(book.url) && this.authorized(book)
        
        //return images? Constants.isImage(book.url) && this.authorized(book): this.authorized(book)
    }

    static logout() {
        this.setUser({})
        this.setUser({}, true)
        //this.store.setItem("read", true)
    }

    static getUserKey(key = "") {

        var user = this.getUser(), value = "";

        if (Object.keys(user).includes(key)) 
            value = user[key];
        return value
    }

    static alone(params) {
        return (window.matchMedia('(display-mode: standalone)').matches) || 
        (window.navigator.standalone) || 
        document.referrer.includes('android-app://');
    }

    static isMobile() {
        return /Mobi/i.test(window.navigator.userAgent);
    } 

    static mainMenu() {
        return this.alone() ? "main-menu":"";
    }

    static disableMainMenu() {
        this.persistence.setItem("main-menu", "");
    }

    static enableMainMenu() {
        this.persistence.setItem("main-menu", "main-menu");
    }
}

export default Session;