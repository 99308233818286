import axios from "axios";
import $ from "jquery";
import { Confirm, Report, Loading } from "notiflix";
import { FaFile, FaFileCsv, FaFileExcel, FaFilePdf, FaFileWord } from "react-icons/fa";
import Session from "./components/Session";

class Constants {
    static api      = "/books";
    static uploads  = `${this.api}/uploads/` 
    static images   = `${this.uploads}images` 
    static auth     = `${this.api}/auth`
    static debug    = true
    static policy   = { 
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all'
        },
        mode: "no-cors"
    }

    static absoluteApiUrl() {
        return this.api.toLowerCase().startsWith("http") ? this.api : window.location.origin;
    }

    static isObject(item) {
        return item.constructor.name === "Object";
    }

    static getType(file, _strict = false, title = false) {
        var 
        
        itype = "pdf";
        if (file.toLowerCase().endsWith("docx")) itype = "doc";
        if (file.toLowerCase().endsWith("csv")) itype = "csv";
        if ((/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file)) itype = "img";
        else {
            if (_strict) {
                itype = file.includes(".") ? file.split(".").pop() : "unknown";
            }
        }
    
        return title ? this.titleCase(itype):itype;
      }

    static isImage(file) {
        return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file)
    }

    static titleCase(text) {
        if (!text) return text;
        var 
        
        items = text.split(" "),
        item  = items.length > 1? items[0] : text;
        return items.length === 1 ? 
        item.charAt(0).toUpperCase() + item.substring(1, item.length) : 
        items.map(this.titleCase).join(" ")
      }

    static tell(title, message, type="success"){
        if (type==="info")
            return Report.info(title,message,"Done")
        if (type==="warn")
            return Report.failure (title,message,"Done")
        Report.success(title,message,"Done")
    }

    static verify(operation, message, callback, data = {}, cancel = null) {
        let tell = this.tell
        Confirm.show(operation, 
            `Are you sure you want to ${operation.toLowerCase()}?`, "Yes", "No",
            async () => {
                $(".deleting")
                .css({background: "#444444", color: "#ffffff"})
                .text("...")

                // Finish
                await callback(data)
                tell(operation, message)
            },
            function cancelCb(){
                if (cancel) cancel(data);
                $(".deleting").toggleClass("deleting")
            } )
    }

    static formatSize( size, str = true ) {
        var 
        measure = "bytes",
        real = size;

        if ( real > 1000) {
            measure = "Kb"
            real /= 1024
        }
        if ( real > 1000) {
            measure = "Mb"
            real /= 1024
        }
        if ( real > 1000) {
            measure = "Gb"
            real /= 1024
        }

        return str? `${real.toPrecision(3)} ${measure}` : real.toPrecision(3)
    }

    static request(operation, callback, data = {}, message = "", read = false) {
        let tell = this.tell
        Confirm.show(operation, 
            `Would you like to ${operation.toLowerCase()}?`, "Yes", "No",
            async () => {

                // Finish
                await callback(data)
                //tell(operation, message.length? message: `${operation} verified!`)
            },
            function cancelCb(){
                if (read) {
                    Session.setRead(false);
                    tell(`Welcome voice disabled!`)
                }
                
            } )
    }

    static sendFeedback(_form) {
        var form = new FormData(_form)

        for (const [, value] of form) {
            if ((typeof value ) !== "string") 
                return $("#feedback-status").html("<span className='danger'>Your Data is Incorrect!</span>");
        }

        Loading.standard()
        axios.post(this.api + "/feedback", form, {headers: {
            "Content-Type": "application/json"
        }}).then(resp => {
            Loading.remove()
           this.tell("Feedback Success!", "We have received your feedback. We'll be in touch.")
            $("#popup-root > div.popup-overlay").closest("#popup-root").toggle()
        })
    }

    static makeBook (name, data, callback = () => {}) {
        var 
        
        form = new FormData();
        //template = data;

        if (!Session.userID()) return;
        form.append("name", name)
        form.append("data", data)
        form.append("url", name)
        form.append("category", "6403cfce6832e7d1f2ec709e")
        form.append("author", Session.getUserKey("firstname"))
        form.append("user", Session.userID())

        axios.post(this.api + "/new", form, {headers: {
            "Content-Type": "application/json"
        }})
        .then(data => console.log(data))
        .finally( callback )
    }

    static getFileIcon(_file = "") {
        var file = _file.toLocaleLowerCase()

        if (file.endsWith("docx")) 
            return (<FaFileWord style={{color: "#00428B"}}/>)
        if (file.endsWith("xls") || file.endsWith("xlsx") || file.endsWith("csv")) 
            return (<FaFileExcel />)
        if (file.endsWith("pdf")) 
            return (<FaFilePdf style={{color: "#b30b00"}}/>)
        if (file.endsWith("csv")) 
            return (<FaFileCsv />)
        return (<FaFile />)

    }
    
}

export default Constants;