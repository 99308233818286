import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import Queries from "./queries";

const Special = Queries.users()

function AuthorList() {

  const {loading, error, data} = useQuery(Special)

  if (loading) return (
    <div>
      <ReactLoading type="cubes" width="50px" height="50px" />
    </div>
    )

    if(error) return (
        <div>
          {"Data Load Failed."}
        </div>
    )

  return (
    <ul>
      {data.users.length > 0? data.users.map(({id, username, firstname, lastname, uploads}) => (
          uploads.length > 0 && <li key={id}>
            <Link to={"/b!" + id} title={firstname + " " + lastname}>{firstname}</Link>
          </li>
      )) : (<div>No Contributors</div>)}
    </ul>
  );
}

// Export it
export default AuthorList;
