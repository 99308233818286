import { useQuery, useMutation, gql } from "@apollo/client";
import { FaCaretRight, FaFilePdf} from "react-icons/fa";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import Queries from "../queries";

const GroupsQuery = Queries.distinctBooks()



function Groups() {

    const {loading, error, data} = useQuery(GroupsQuery)

    if (loading) return (
        <div>
            <ReactLoading type="cubes" width="50px" height="50px"/>
        </div>
    )

    if(error) {
        const message = "Data Load Failed."
        return (
            <div>{message}</div>
        )
    }
    return (
        <nav>
            <ul>
                <h3 className="">{ 
                data.getBooks.length > 0? "": 
                <span>No added genres</span> }</h3>
                {data.getBooks.map(({genre, id}) => (
                    <li key={genre}>
                        <Link to={"/" + genre}><FaFilePdf/> {
                            genre.length > 10 ? genre.substring(0, 15).concat("...") : genre
                        }</Link>
                    </li>
                ))}
                {data.getBooks.length > 0 && (
                    <div>
                        <hr></hr>
                        <li key="all">
                            <Link to="/category"><FaCaretRight/> Mixed</Link>
                        </li>
                    </div>
                )}
            </ul>
        </nav>
    );
  }
  
  export default Groups;
  