import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Popup from "reactjs-popup";
import Constants from "../constants";
import $ from "jquery"
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaFileSignature, FaSave } from "react-icons/fa";
import axios from "axios";


function BookEditor (props) {
    const 
    {state} = useLocation(),
    navigate = useNavigate(),
    [read, setRead] = useState(false),
    [edit, setEdit] = useState("File.docx"),
    [data, setData] = useState(state && state.hasOwnProperty("book") && read !== state.book.url? 
    "<h3 style='text-align:center;'><i>Loading...</i></h3>": ""),

    // https://quilljs.com/docs/modules/toolbar/
    toolbarOptions = [
        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        //['blockquote', 'code-block'],
        //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        
        [{ 'align': ["", "center", "right", "justify"] }],
        ["image", "link"]
        //['clean']                                         // remove formatting button
      ],
      
    _formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'color',
        "align",
        "script",
        "background"
      ];

    var book = null;

    if (state && state.hasOwnProperty("book")) book = state.book;
    
    if (book && read !== book.url ) {
        // axios({
        //     method: 'get',
        //     url: `${Constants.api}/edit/${book.url}`,
        //     headers: {
        //         'Content-Type': 'text/plain'
        //     },
        //    responseType: 'text'
        //   })
        // axios.get(`${Constants.api}/${book.url}`)
        // .then(_data => {
        //     console.log(_data)
        // })
        var fl = `${book.url}`
        if ( !fl.toLowerCase().endsWith("pdf") ) {
            $.get(`${Constants.api}/edit/${book.url}`, (_data) => {
                setData(_data);
                setRead(book.url)
            })
        } else {
            setData("Sorry, we don't support reading <b>PDF</b> yet");
            setRead(book.url)
        }
        
    }

    return (
        <div style={{minWidth: "80%", margin: "auto"}}>
            <div>
                <button 
                onClick={e => { navigate(-1)}}
                style={{
                marginLeft: "0",
                fontSize: "16px", 
                fontFamily:"georgia", 
                color: "#008185",
                boxShadow: "none",
                border: "0",
                padding: "10px 5"
                }}>&larr; Back
                </button>
            </div>
            <div className="specialGenre my-editor" style={{
                marginBottom: "5vh", 
                minHeight: "50vh",
                padding: 0,
                border: "1px solid #ffffff", 
                minWidth: "80%"}}>
                <div style={{
                    display:"block", width:"100%", 
                    padding: "5px 0",
                    height: "25px", 
                    textAlign:"right", background: "white"}}>
                        <Popup className="modal" trigger={
                        <button style={{
                            background: "inherit", 
                            border: "none",
                            //borderBottom: "1px dashed #c9c9c9", 
                            borderRadius: "0px",
                            padding: "5px 0 3px",
                            boxShadow: "none", 
                            marginRight: "10px", 
                            color: "#008185",
                            display:"flex",
                            float:"right",
                            fontSize: "16px",
                            letterSpacing: "1.5px",
                            fontFamily: "georgia",
                            alignItems: "center"}}>
                            <FaFileSignature/> &nbsp;File
                        </button>

                        } position="bottom right">
                            <div style={{padding: "10px 15px 10px 10px", background: "#efefef", textAlign:"center"}}>
                            <input type="txt" name="file" id="book-name" 
                            value={book? book.url : edit} style={{
                                minWidth: "15vw",
                                margin: "5px auto !important"}}
                                onChange={ e => setEdit(e.target.value) }/>
                                <button className="add" onClick={e => {
                                    var 
                                    file = $("#book-name").val();
                                    Constants.makeBook(file, data, () => {
                                        window.location.href = "/app"
                                    })
                                }}>Save</button>
                            </div> 
                        </Popup>
                    </div>
                <ReactQuill 
                    value={data}
                    modules={
                        {toolbar: toolbarOptions}
                    }
                    theme="snow"
                    style={{border: "none"}} 
                    formats={_formats}
                    onChange={setData} />
            </div>
       </div>
    )
}

export default BookEditor;