import { useQuery } from "@apollo/client";
import { 
    FaCaretRight, 
    FaFilePdf,
    FaMusic, FaVideo, FaBookReader,
    FaUserGraduate, FaBookMedical,
    FaBullhorn, FaGem,
    FaVoteYea, FaTree, FaLaptopCode,
    FaBriefcase, FaDollarSign, FaBook
} from "react-icons/fa";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import Queries from "./queries";

const GenreQuery = Queries.categories()

function GenreList() {

    const {loading, error, data} = useQuery(GenreQuery)

    if (loading) return (
        <div>
            <ReactLoading type="cubes" width="50px" height="50px"/>
        </div>
    )

    function getIcon(_type) {
        var icon = "pdf",
        type = _type.toLowerCase();

        if ( type === "music") icon = "music";
        if ( ["film", "movie", "video"].includes(type)) icon = "film"
        if ( ["education", "maths", "biology"].includes(type) ) icon = "school"
        if ( ["it", "computers"].includes(type)) icon = "computer"
        if ( type === "literature") icon = "book"
        if ( type === "health") icon = "health"
        if ( type === "business") icon = "business"
        if ( type === "government") icon = "government"
        if ( type.startsWith("natural") ) icon = "nature"
        if ( type === "agriculture") icon = "agriculture"
        if ( type === "economics") icon = "economics"
        if ( type === "jobs") icon = "jobs"
        if ( type === "general") icon = "general"

        return icon
    }

    if(error) {
        const message = "Data Load Failed."
        return (
            <div>{message}</div>
        )
    }

    return (
        <nav>
            <ul>
                <h4 className="category">{ 
                data.categories.length > 0? "": 
                <span>No added categories</span> }</h4>
                {data.categories.map(({id, name, books}) => (
                    books.length > 0 && <li key={name}>
                        <Link to={"/" + name}>
                            { 
                            getIcon(name) === 'music'? <FaMusic/> : 
                            getIcon(name) === "film" ? <FaVideo /> : 
                            getIcon(name) === "school" ? <FaUserGraduate /> : 
                            getIcon(name) === "computer"? <FaLaptopCode />:
                            getIcon(name) === "book" ? <FaBookReader />:
                            getIcon(name) === "health"? <FaBookMedical />:
                            getIcon(name) === "business"? <FaBullhorn />:
                            getIcon(name) === "government"? <FaVoteYea />:
                            getIcon(name) === "nature"? <FaGem />:
                            getIcon(name) === "jobs"? <FaBriefcase />:
                            getIcon(name) === "agriculture"? <FaTree />:
                            getIcon(name) === "economics"? <FaDollarSign />:
                            getIcon(name) === "briefcase"? <FaBriefcase />:
                            getIcon(name) === "general"? <FaBook />:
                            <FaFilePdf />
}
                            &nbsp;{ name.length > 15 ? name.substring(0, 15).concat("...") : name }</Link>
                    </li>
                ))}
                {data.categories.length > 0 && (
                    <div>
                        <hr></hr>
                        <li key="all">
                            <Link to="/category"><FaCaretRight/> Mixed</Link>
                        </li>
                    </div>
                )}
            </ul>
        </nav>
    );
  }
  
  export default GenreList;
  