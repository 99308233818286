import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "./skyblue.css"
import './style.css';
import './footer.css';
import './mobile.css';
import App from './App';
import Home from "./components/Home";
import Board from "./components/groups/Board";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Register from './components/Register';
import Session from './components/Session';
import $ from "jquery";


const root = ReactDOM.createRoot(document.getElementById('root'));

if ( ! Session.isMobile() ) {
    $("[name='theme-color']").attr("content", "#d5d5d5");
}
root.render(
  
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={ Session.loggedIn()? <App /> : <Home />} />
        <Route path="/login" element={ <Home />} />
        <Route path='/register' element={<Register />}/>
        <Route path="/app/*" element={<App speak={true} />} />
        <Route path="/groups" element={<Board />} />
        <Route path="*" element={<App speak={true} />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
