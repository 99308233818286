import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Session from "./Session";
import ReactLoading from "react-loading";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from "./Footer";

function Home(props) {

    const navigate = useNavigate()
    const [state, setState] = useState({username: "", password: "", persist: false})
    
    function useSubmit(e) {
        e.preventDefault()
        Session.login(state.username, state.password, navigate, "/app", state.persist)
        //navigate("/app")
    }
    function updateState(key, value) {
        if (!Object.keys(state).includes(key)) return;
        state[key] = value

        setState(state)
    }

    if (Session.loggedIn()) window.location.href = "/app";

    var title = props.title || "Home"
    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
      });

    return (
        <div className="container">
            <div className="site-logo">
                <img src="/images/logo.ico" width="35" height="30"/> Library          
                </div>
            <div className="home">
                <div className="center"><h2 style={{color: "#ffffff"}}>Join Us</h2></div>
                <form onSubmit={ useSubmit }>
                    <legend id="lognote" style={{display: "none"}}>
                        <ReactLoading type="spokes" style={{margin: "0 auto", height: "40px", width: "40px"}} height="50px" width="50px" color="#dedede"/>
                    </legend>
                    <input required="required" placeholder="Username" name="username" onChange={(e) => updateState('username', e.target.value)}/>
                    <input type="password" minLength="6" required="required" placeholder="******" name="password" onChange={(e) => updateState('password', e.target.value)}/>
                    <div className="remember">
                        <input type="checkbox" name="persist" onChange={(e) => updateState('persist', e.target.checked)}/><label>Remember Me</label>
                    </div>
                    <div className='center'>
                        <button type="submit" className="add">LOGIN</button>
                    </div>
                    <div className='notice'>
                        Don't have account? <Link to="register" className="link">Sign Up</Link>.
                    </div>
                </form>
            </div>
            <div className="message">
                <h3>Study With Library Pure.</h3>
                <div>
                    Enjoy our free book collection space for free for all uses.<br/>
                    We have dedicated in providing free study services to help study with fun.<br/>
                    For our passion with books, 
                    We found it appealing to make the service accessible to everyone,
                     as long as Our condition of usage are met.
                    Sharing is caring, share this site and or it's content with family and friends.
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Home;