// components
import BookList from "./components/BookList";
import GenreList from "./components/Genre";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import $ from "jquery";
import 'reactjs-popup/dist/index.css';
import {  
  FaSignOutAlt,
  FaSearch,
  FaComment,
  FaPaperPlane,
  FaBell,
  FaBars,
  FaDownload
} from "react-icons/fa";
import Category from "./components/Category";
import Grid from "./components/Grid";
import Gallery from "./components/Gallery";
import AuthorList from "./components/Authors";
import BookReader from "./components/BookReader";
import Menu from "./components/Menu";
import Constants from "./constants";
import Session from "./components/Session";
import Favorite from "./components/Favorite";
import usePWA from "react-pwa-install-prompt";
import axios from "axios";
import { Loading } from "notiflix";
import BookEditor from "./components/BookEditor";
import Installer from "./components/Installer";


const client = new ApolloClient({
  uri: `${Constants.api}/g_api`,
  credentials: "omit",
  fetchOptions: Constants.policy,
  cache: new InMemoryCache()
})

function App(props) {

  // PWA
  const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA()

  const isInstalled = () => isInstallPromptSupported && isStandalone
  const location = useLocation();

  const onClickInstall = async (data = {}) => {
    const didInstall = await promptInstall()
    if (didInstall) {
      // User accepted PWA install
      Session.enableMainMenu();
    }

  }

  //if (isInstallPromptSupported && !isStandalone
     //&& !Session.read()
  //)
  //Constants.request("Install Application", onClickInstall);
  //PWA End

  var speak = false
  
  const 
  navigate = useNavigate();

  if (location.pathname.toLowerCase().endsWith("install") && !isInstalled()) {
    return (
      <div className="automate center">
        <img src="/images/logo.ico" width="75px" height="75px" alt="&nbsp;Error"/>
        <div className="stranger">
          Welcome to our books library application.
        </div>
        <div>
          <button className="add" onClick={e => onClickInstall()}>Install <FaDownload /></button>
        </div>
      </div>
     )
  }

  if( !Session.loggedIn()) {
   return (
    <div className="automate center">
      <img src="/images/error.png" width="50px" height="50px" alt="&nbsp;Error"/>
      <div className="stranger">
        You must be logged in first to access the application.
      </div>
      <div>
        <button className="add" onClick={e => navigate("/")}>Log In</button>
      </div>
    </div>
   )
  }
  
  if (props.speak) {
    speak = new SpeechSynthesisUtterance()
    speak.text = "Hello... I am Amin, Welcome to the library!"
  }

  if(window.speechSynthesis.speaking)
  window.speechSynthesis.cancel()

  $("body").bind("select copy cut paste", (e) => {
    if(["textarea", "input"].includes(e.target.tagName.toLowerCase())) return true;

    e.preventDefault()
    alert("Copying not allowed")
    
  }) 

  function search(e) {
    var 
    
    searchBar = document.querySelector("#search");

    if (!searchBar) return;
    var 
    parent    = searchBar.parentNode,
    visible   = searchBar.style.visibility;

    parent.style.border = visible === "visible" ? "none" : "1px solid #fefefe";
    parent.style.background =  visible === "visible" ? "inherit" : "white";
    searchBar.style.visibility = visible === "visible" ? "hidden" : "visible";
    
  }

  // function showIt(location, show = true) {
  //   var 
    
  //   searchBar = document.querySelector("#search");
  //   if ( ! searchBar ) return;

  //   var
  //   parent    = searchBar.parentNode;
  //   //visible   = searchBar.style.visibility;

  //   if( parent && !show) parent.style.display = "none";
  // }

  function upload ( _file ) {
    var 
    
    profile = new FormData(),
    file = _file;

    profile.append("profile", file, file.name)
    profile.append("user", Session.getUserKey("_id"));

    if ( !Constants.isImage(file.name) ) return Constants.tell(
      "Invalid File", 
      "Only images are allowed for upload.",
      "warn"
    )

    Loading.hourglass()
    axios.post(Constants.api + "/profile", profile, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((resp) => {
      let results = resp.data

      if (results.hasOwnProperty("user") && results.user.hasOwnProperty("profile")) {
        var 
        user = results.user;
        Session.setUser(user);

        $("#user-logo").attr("src", `${Constants.images}/${user.profile}`);
        $("#zoom").attr("src", `${Constants.images}/${user.profile}`);
        
        Loading.remove()
        Constants.tell("Image Uploaded", "Your image was uploaded successfully!")
      }
    })
  }
  async function uploadProfile(el) {
    
    $("#uploadProf").trigger("click")
    
  }
  

  function toggle(_target, check) {
      var 
      
      leftMenu = $("#main > div.leftMenu"),
      target = $(_target);
      
      if ( !leftMenu.is(":visible") ){
         if(target.attr("#toggle") !== "toggle" && target.closest("#toggle").attr("id") !== "toggle") {
          return
        }
        //$("body, .automate, .specialGenre, .gallery, .center nav").css({background: "rgba(0, 0, 0, .6)"}); 
      }

      leftMenu.toggle();
      
  }

  return (
    <ApolloProvider client={client}>
      <div id="main" onClick={e => {
        if($("#toggle").is(":visible"))
        toggle(e.target, true)
        else {
          if ( !$(".leftMenu").is(":visible"))
            $(".leftMenu").show();
        }}
      }>
        <div className="leftMenu">
        <h3>Categories</h3>
          <GenreList/>
        </div>
        
          <div className={`content ${Session.mainMenu()? 'viewer': ''}`}>
            
            <div className={`center ${Session.mainMenu()}`}>
             <div className="title-container">
                <button style={{
                  float: "left", 
                paddingLeft: Session.mainMenu()? "": "10px" }} 
                id="toggle"><FaBars style={{color: ! Session.alone()? "#007185":"#ffffff" }}/></button>
                <Popup style={{background: "#ffffff"}} trigger={<span className='title'>Feedback</span>} position="top left">
                  <form method="POST" id="feedback" onSubmit={e => {e.preventDefault(); Constants.sendFeedback(e.target)}}>
                    <legend style={{textAlign:"center"}}>WRITE YOUR FEEDBACK</legend>
                    <div id="feedback-status"></div>
                    <input type="email" name="email" value={Session.getUserKey("email")} onChange={e=> ""} placeholder="example@domain.com"/>
                    <textarea name="message" placeholder="Message..."></textarea>
                    <div className='center'>
                      <button type="submit" className="add"><FaPaperPlane/> Send</button>
                    </div>
                  </form>
              </Popup>
          
              </div>
              <Menu/>
            </div>
            <div className="search">
              <input id="search" placeholder="search..."/>
              <button onClick={search}><FaSearch/></button>
            </div>
              <Routes>
                <Route index path="/" element={<BookList speak={speak} />} />
                <Route path="/category" element={<Category />} />
                <Route path="/read" element={<BookReader />} />
                <Route path="/editor" element={<BookEditor />} />
                <Route path="/grid" element={<Grid/>} />
                <Route path="/grid/sort" element={<Grid/>} />
                <Route path="/gallery" element={<Gallery/>} />
                <Route path="/sort" element={<BookList />} />
                <Route path="/favorite" element={<Favorite />} />
                {/* <Route path="/install" element={<Installer installed={isInstalled} installer={onClickInstall} />} /> */}
                <Route path="*" element={<Category />} />
              </Routes>
          </div>

        <div className="rightMenu">
        {/* <select>
            <option key="" value="">Sort By</option>
            <option key="name" value="name">Name</option>
            <option key="genre" value="genre">Genre</option>
            <option key="author" value="author">Author</option>
          </select> */}
          {/* <hr/> */}
          <div className="user">
          <Popup 
                trigger={<button id="notify" className={`content ${Session.mainMenu()? 'white': ''}`}>
                    <sub>1</sub><FaBell/>
                  </button>} position="bottom right">
                  <div className="center" id="notifications">
                    Welcome to the library.
                  </div>
                </Popup>
            <div className="center">
              <input type="file" accept="image/*" name="profile" id="uploadProf" style={{
                display: "none",
                height: "0px"
              }} onChange={e => upload(e.target.files[0])}/>
              <Popup 
                trigger={<img src={
                  Session.getUserKey("profile")? `${Constants.images}/${Session.getUserKey('profile')}` :
                  `/images/dummy.png`} alt="" width="40px" height="40px" id="user-logo"/>}
                position="bottom right" className="center">
                  <div className="center">

                    <img className="zoom" id="zoom" src={
                  Session.getUserKey("profile")? `${Constants.images}/${Session.getUserKey('profile')}` :
                  `/images/dummy.png`} height="250" style={{
                    maxWidth: "250px",
                  minHeight: "200px", minWidth: "200px"}} alt="Change profile"
                      onClick={e => uploadProfile(e.target)}
                    />
                    <div className="center">
                      <button className="logout" onClick={e=> {
                      e.preventDefault()
                      Session.logout()
                      navigate("/login")
                    }} >LOG OUT <FaSignOutAlt /></button>
                    </div>
                  </div>
              </Popup>
              <div className="center">
                  <button style={{margin: "0 auto !important", 
                  background: "inherit", 
                  border: "none", 
                  textAlign: "center",
                  display: "none"}}>
                    {Session.getUser().username}
                  </button>
                </div>
              
            </div>
          </div>
      
          <nav className="authors">
            <h3>Contributors</h3>
            <AuthorList/>
          </nav>
          <Popup trigger={<button id="chatMe">
            ... <FaComment/>
          </button>} position="top right">
              <form method="POST" id="feedback" onSubmit={e => {e.preventDefault(); Constants.sendFeedback(e.target)}}>
                <legend style={{textAlign:"center"}}>WRITE YOUR FEEDBACK</legend>
                <div id="feedback-status"></div>
                <input type="email" name="email" value={Session.getUserKey("email")} placeholder="example@domain.com"/>
                <textarea name="message" defaultValue={"Message..."}></textarea>
                <div className='center'>
                  <button type="submit" className="add"><FaPaperPlane/> Send</button>
              </div>
              </form>
          </Popup>
        </div>
        {/* <div className="footer">&copy; Amin Matola</div> */}
      </div>
   
    </ApolloProvider>
  );
}

// Export it
export default App;

// <a href="https://www.freepik.com/free-photo/horizontal-shot-satisfied-college-student-uses-new-cool-app-cell-phone-carries-notepad-writing-notes-wears-spectacles-silk-scarf-knitted-sweater-isolated-blue-wall_11577694.htm#query=student&position=44&from_view=keyword&track=sph">Image by wayhomestudio</a> on Freepik