import { FaEnvelope, FaFacebook, FaLinkedin, FaMailBulk, FaTwitter, FaWhatsapp } from "react-icons/fa"

function Footer (props) {

    return (
        <div className="site-footer">
            <nav>
                <ul>
                    <li><a style={{color: "black"}}></a></li> 
                    <li><a href="https://facebook.com/purelibrary"><FaFacebook/></a></li>
                    <li><a href="https://api.whatsapp.com/send?phone=265997279652"><FaWhatsapp/></a></li>
                    <li><a href="https://www.linkedin.com/in/amin-martola"><FaLinkedin/></a></li>
                    <li><a href="https://twitter.com/aminmartola"><FaTwitter/></a></li>
                    <li style={{marginLeft: "auto", color:"white"}}>
                        <a href="mailto:info@librarypure.com" 
                        style={{
                            color:"#ffffff", 
                            fontSize:"14px", 
                            fontFamily:"georgia",
                            display: "inline-flex",
                            alignItems: "center",
                            textTransform: "lowercase"
                        }}>
                            <FaEnvelope style={{color: "black"}}/>&nbsp;info@librarypure.com
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Footer;