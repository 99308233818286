import { FaDownload, FaBookReader } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import Constants from "../constants";
import Session from "./Session";

var server = Constants.api;



function Favorite(props) {
  
  const location = useLocation()
  const books = Session.getFavorites().reverse()
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  function formatDate(_date) {
    var 
    
    date = new Date(_date),
    today = new Date();

    if ((today.getDate() - date.getDate() ) === 1) 
      return "Yesterday";
    if ((today.getDate() - date.getDate() ) > 1) 
      return `${appendZ(date.getDate())} ${months[date.getMonth()]}, ${date.getFullYear()}`;

    return `${appendZ(date.getHours() % 12 || 12)}:${appendZ(date.getMinutes())} ${date.getHours() >= 12? "PM":"AM"}`
  }
  
  function appendZ(t) {
    return t < 10? `0${t}`: t;
  }
  
  return (
    <div className={`specialGenre ${Session.mainMenu()? 'ground': ''}`}>
        <h2 className="center">Favorite Documents ({books.length})</h2>
        <ul>
            {
                books.length > 0 ? 
                books.map(({name, url, time}) => (
                    <li key={time} className="favorite">
                        <div>
                            <Link to={{pathname: "/read"}} state={{name: name, file: url}} className="flexible">
                            <img  alt="Cover" width='17' height='17' src={"/images/" + Constants.getType(url) + ".png"} /> 
                            &nbsp;{name.length > 20? name.substring(0, 20) + "..." : name}
                            </Link>
                        </div>
                        <span style={{textAlign: "right", color: "#c0c0c0"}}>{
                            `${formatDate(time)} `
                        
                        }
                        </span>
                    </li>
                    
                )) : (
                  <div className="center">
                   You don't have favorite documents yet.
                   </div>
                  )
            }
            
        </ul>
    </div>
  );
}

// Export it
export default Favorite;
