import { useMutation, useQuery } from "@apollo/client";
import { FaDownload, FaBookReader, FaShare } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading"
import Queries from "./queries";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import Constants from "../constants";
import Session from "./Session";
import $ from "jquery";
import { useState } from "react";
import { ShareSocial } from "react-share-social";
// import DocViewer from "react-doc-viewer";
// import { PDFReader } from 'react-read-pdf';

var server = Constants.api;

const
BookQuery   = Queries.getBooks(),
GenreQuery  = Queries.distinctBooks(),
RemoveBook  = Queries.removeBook();

function Category(props) {
  
  const location = useLocation();
  //const Special = location.pathname.length > 1 ? Queries.getBooks() : Special

  const 
  
  cat      = titleCase(getCat(location.pathname)), 
  navigate = useNavigate(),
  [state, setState] = useState({item: "", name: ""});

  var vars       = {variables: {sort: cat.endsWith("sort")}}
  var Special    = Queries.getBooks()

  
  if (location.pathname.indexOf("!") >= 0) {
    Special     = Queries.booksByUser()
    vars["variables"]["user"] = cat
  }

  else if (! ["category", "sort"].includes(cat.toLowerCase())) {
    Special = Queries.readGenred()
    vars["variables"]["category"] = cat
  }
  else {
    vars["variables"]["max"] = "25";
  }

  //console.log(vars)
  const 
  
  {loading, error, data} = useQuery(Special, vars),
  [removeBook]  = useMutation(RemoveBook, {refetchQueries: () => [
    {
      query: Special
    },
    {
      query: BookQuery
    },
    {
        query: GenreQuery
    }]
  }),
  getType = Constants.getType;

  function titleCase(text) {
    if (!text) return text;
    var 
    
    items = text.split(" "),
    item  = items.length > 1? items[0] : text;
    return items.length === 1 ? 
    item.charAt(0).toUpperCase() + item.substring(1, item.length) : 
    items.map(titleCase).join(" ")
  }

  function getCat( _path ) {
    var path = decodeURI(_path);
    return path.length > 1 && path.startsWith("/")? 
    path.split("/").pop().split("!").pop() : "" 
  }

  if (loading) return (
    <div className="specialGenre">
        <h2 className="center">Your Documents</h2>
        <ReactLoading type="bars" height="50px" width="50px" color="#dedede"/>
    </div>
   )

  if(error) {
    const message = error.name === "ApolloError" ? "Connection Problem" : "Data Load Failed."
    //console.log(error)
    return (
    
    <div className="specialGenre">
        <h2 className="center">Your Books</h2>
        <div>{message}</div>
    </div>
    )
  }

  $("body").click((e) => {
    $(".context:visible").toggle()
    if ($("#details").is(":visible") && !$(e.target).hasClass("details")) $("#details").toggle()
  })

  $("body").contextmenu((e) => {
    
      const 
      
      item = $(e.target).closest("li");

      $(".context:visible").toggle()

      if (item.hasClass("deletable")) {
        e.preventDefault()
        setState({item: item.attr("id"), name: item.text()})

        let x = e.pageX, y = e.pageY;

        $(item).find(".context").css({
          display: "block",
          position: "fixed",
          top: `${y}px`,
          left: `${x}px`,
        }).fadeIn(200);
      }
      else setState({item: ""})
      
      //alert(x + " " + y)
  })

  const first = (data.books || data.getFilteredBooks || data.booksByUser).length > 0? 
  (data.books || data.getFilteredBooks || data.booksByUser)[0].user: false;
  const books = (data.books || data.getFilteredBooks || data.booksByUser).filter(book => Session.canView(book));

  function doAction(what = "exit", ev = null) {
      $("#details:visible").toggle()
      if (what === "delete") 
        Constants.verify(
          "Delete item " + state.name, 
          "Item deleted successfuly!", removeBook, 
          {variables: {id: state.item}}
        )
        else if (what === "read") {
          var 
          
          _book = books.filter(b => b.id === state.item), book; 
          if(_book.length < 1) return;
           
          book = _book[0];
          
          navigate("/read", {state: {name: book.name, file: book.url}})
        }
        else if (what === "details") {
          var _book = books.filter(b => b.id === state.item);

          if(!ev || !_book.length) return console.log("Even undefined");
          let 
          
          x = "10%", //$(".context:visible").offset().left || ev.pageX, 
          y = ev.pageY,
          book = _book[0];
          
          $("#details")
          .css({
            display: "block",position: "fixed",top: `${y}px`,left: `${x}px`,
          })
          .fadeIn(1500);

          $("#book-name").text(book.name.length > 20? book.name.substring(0, 20) + "..." : book.name)
          $("#book-author").text(book.author)
          $("#book-category").text(book.category.name)
          $("#book-uploader").text(`${book.user.firstname} ${book.user.lastname}`)
          
        }
        else if ( what === "edit") {
          var _book = books.filter(b => b.id === state.item);

          if(!_book.length) {
            return false;
          }
          let book = _book[0];
          navigate("/editor", {state: {book: book}});
          // Constants.tell(
          //   "Not Allowed!", 
          //   `Sorry ${Session.getUserKey('firstname')}, editing is not enabled yet.`,
          //   "warn")
        }
        
      $(".context:visible").toggle()
      $("#context").toggle()

  }

  return (
    <div className="specialGenre ground">
        <h2 className="center"> { 
            cat !== 'category' && books.length > 0?
             <span>{
                location.pathname.includes("!")? books[0].user.firstname + "'s":
                cat.toLowerCase() !== "category"? cat : ""
            }</span>: "" 
        } Documents ({books.length})</h2>
        <ul>
            {
                books.length > 0 ? 
                books.map(({id, name, category, url, author, user}) => (
                  
                    <li key={id} className="deletable" id={id} title="Right-click for context menu.">

                    <div className="context">
                        <ul>
                          <li className="details" onClick={e => doAction("read", e)}>Open File</li>
                          <li className="details" onClick={e => doAction("details", e)}>Details</li>
                          <li onClick={e => doAction("edit")}>Edit File</li>
                          <li onClick={e => doAction("delete")}>Delete</li>
                          <li>
                          <Popup trigger = {
                                  <b>Share</b>
                                } position="center center" modal nested>
                                    {close => (
                                      <div className="modal">
                                        <button className="close" onClick={close}>
                                          &times;
                                        </button>
                                        <ShareSocial title={"Hi " + Session.getUserKey("firstname") + ", Share book to.."}
                                        url={`${Constants.absoluteApiUrl()}/${url}`}
                                        socialTypes={["facebook", "whatsapp", "twitter", 
                                        "linkedin", "telegram"]} 
                                        style={{
                                        root: {
                                          maxWidth: "90vw",
                                          margin: "auto",
                                          left: 0
                                        },
                                        title: {
                                            color: "#034c58",
                                            textAlign: "center"
                                        },
                                        copyContainer: {
                                          background: "#034c58"
                                        },
                                        copyUrl: {
                                          overflowX: "hidden",
                                          color: "#000000"
                                        }}}
                                        onSocialButtonClicked={ data => {}}
                                        />
                                      </div>
                                      )
                                    }
                                  </Popup>
                          </li>
                          <li className="final" onClick={e => doAction()}>Close</li>
                        </ul>
                      </div>

                        <Popup trigger={
                          <div>
                            <img  alt="Cover" width='17' height='17' src={"/images/" + getType(url) + ".png"} />
                            &nbsp;
                          <Link to={{pathname: "/read"}} state= {{name: name, file: url}}>
                          {name.length > 25? name.substring(0, 25) + "..." : name}
                          </Link>  
                        </div>
                      } position="center center">
                            
                            <img alt="Cover" src={`/images/${getType(url)}.png`} 
                            width="100%" height="200px"
                            style={{background: "#ffffff"}}/>
                            <div className="info">
                                <table>
                                    <tbody>
                                        <tr><td><b>Book Name:</b></td><td> {name.length > 10? name.substring(0, 10) + "..." : name}</td></tr>
                                        <tr><td><b>Author:</b></td><td> {author}</td></tr>
                                        <tr><td><b>Category:</b></td><td> {category.name}</td></tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td><Link to={{pathname: "/read"}} state= {{name: name, file: url}}><FaBookReader/> Read</Link></td>
                                            <td>
                                                <a href={server + "/uploads/books/" + url}><FaDownload/> Save</a>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            
                        </Popup>
                        <div>
                        <a href={server + "/uploads/download/" + url} target="_parent" download><FaDownload /></a>
                        </div>
                        
                    </li>
                    
                )) : (
                  <div className="center">
                   
                   { 
                      location.pathname.includes("!") && first? 
                      (<span><b>{first.id !== Session.userID()? first.firstname : "You"}</b> haven't shared documents{first.id !== Session.userID()? " with you" : ""}.</span>) :
                      (<span>You haven't added <i><b>{location.pathname.includes("!")? "": cat}</b></i> documents yet.</span>)
                    } 
                   
                   </div>
                  )
            }
            
        </ul>
        
        <div id="details">
          <h2>File Details</h2>
          <div><span>Book Name</span><span id="book-name"></span></div>
          <div><span>Author</span><span id="book-author"></span></div>
          <div><span>Category</span><span id="book-category"></span></div>
          <div><span>Uploaded By</span><span id="book-uploader"></span></div>
        </div>
    </div>
  );
}

// Export it
export default Category;
