import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Link } from "react-router-dom";
import Groups from "./Groups";
import Menu from "../Menu";
import {FaCaretRight, FaSignOutAlt, FaUser, FaUserFriends} from "react-icons/fa";
import Popup from "reactjs-popup";
import Constants from "../../constants";


const client = new ApolloClient({
    uri: `${Constants.api}/graphql`,
    cache: new InMemoryCache()
  })

function Board() {
    const data = {books: {}}
    return (
        <ApolloProvider client={client}>
            <div id="main">
                <div className="leftMenu">
                    <h3>Your Groups</h3>
                    <Groups/>
                </div>
                <div className="content">
                    {/*---- Menu ----*/}
                    <div className="center">
                        <div className="title-container"><span className='title'>Library</span></div>
                        <Menu/>
                    </div>
                    <div className="automate">
                        {/* --- Contents -- */}
                        <h1 className="center" id="iter">Group contents</h1>
                        <ul id="book-list">
                            {
                            data.books.length >= 1 ? data.books.map(({id, name, genre, author}) => (
                                <li key={''+id} title={'By '.concat(author.name)}>
                                    <div className="left"><FaCaretRight/> {name} - {genre}</div>
                                    <div className="right">
                                    </div>
                                </li>
                            )) : <div className="center">You have no groups yet.</div>}
                        </ul>
                        {/* -- User and Group Data */}
                    </div>
                </div>

                <div className="rightMenu">
                <div className="user">
                    
                    <div className="center">
                    <Popup 
                        trigger={<img src='/images/amin.jpg' alt="" width="40px" height="40px"/>}
                        position="bottom right" className="center">
                        <div className="center">
                            <img className="zoom" src='/images/amin.jpg' width="100%" alt="User profile"/>
                            <Link to="/" className="logout">LOG OUT <FaSignOutAlt /> </Link>
                        </div>
                    </Popup>
                    </div>
                    <div><a href="/">Matola</a></div>
                    
                </div>
            
                <nav className="authors">
                    <h3>Courses:</h3>
                    {/* <AuthorList/> */}
                </nav>
                <Popup trigger={<button id="friends">
                    <FaUserFriends/>
                </button>} position="top right">
                    <div>
                        <h4 className="center">Group Friends</h4>
                        <ul>
                            <li><span><FaUser /> John</span></li>
                        </ul>
                    </div>
                </Popup>
                </div>
            </div>
        </ApolloProvider>
    )

}

export default Board;