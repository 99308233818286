import { useLocation, Link } from "react-router-dom";
import { 
    FaHome, 
    FaList, 
    FaHeart,
    FaSortAlphaUp,
    FaTh,
    FaImages,
    FaUniversity,
    FaUsers,
    FaPen
  } from "react-icons/fa";
  import $ from "jquery";

function Menu () {

    const location = useLocation(), 
    path    = location.pathname,
    isHome  = path.endsWith("app"),
    read    = path.endsWith("read"),
    sort    = path.endsWith("sort") && path.split("/").length < 4,
    full    = path.endsWith("sort") ? location.pathname : isHome ? "/app/sort": `${location.pathname}/sort`;

    
    function hideSeach(home, read) {
        $(".search").css({display: home || read || sort? "none":"inline-flex"});
        //$(".automate").css({marginTop: home? "100px": "0"})
    }

    $(document).ready(() => hideSeach(isHome, read));

    return (
        <nav style={{display: "flex"}} onClick={(e) => {
            $(".current").toggleClass("current")
            $(e.target).parentsUntil("li").toggleClass("current")
            
        }}>
            <ul className="menu" style={{textAlign: "center", justifyContent: "center", width: "100%"}}>
                <li>
                <Link to='/app'><FaHome/></Link>
                <Link to='/app/category' title="View categories"><FaList/></Link>
                <Link to='/app/grid' title="Make grid"><FaTh/></Link>
                <Link to='/app/gallery' title="View gallery"><FaImages/></Link>
                {/* <Link to={full} title="Sort"><FaSortAlphaUp/></Link> */}
                <Link to='/groups' title="View your groups" id="groups-link"><FaUniversity /> </Link>
                <Link to='/favorite' title="View favorite books"><FaHeart /> </Link>
                <Link to='/groups' title="View your groups" style={{fontSize: "20px", display:"none"}}><FaUsers /> </Link>

                {/* <Link to='/category' title="Help"><FaInfo/></Link> */}
                </li>
            </ul>
        </nav>
    )
}

export default Menu;