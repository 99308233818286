import { Link } from "react-router-dom";
import { useEffect } from "react";
import "../style.css";
import Session from "./Session";

function Register(props) {

    function updateState(key, value) {

    }

    var title = props.title || "Register"
    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
      });

    return (
        <div className="main">
            <form id="regform" onSubmit={e => { e.preventDefault(); Session.register(e.target) }}>
                <legend className="center" id="reginfo"></legend>
                <input required="required" type="text" name="username" placeholder="Username" onClick={e=>updateState("username", e.target.value)}/>
                <input required="required" type="text" name="firstname" placeholder="First name" onClick={e=>updateState("username", e.target.value)}/>
                <input required="required" type="text" name="lastname" placeholder="Last name" onClick={e=>updateState("username", e.target.value)}/>
                <input required="required" type="email" name="email" placeholder="john@doe.com" onClick={e=>updateState("username", e.target.value)}/>
                <input required="required" minLength="6" type="password" name="password" placeholder="******" onClick={e=>updateState("username", e.target.value)}/>
                <input type="tel" name="mobile" placeholder="(+265) 881014250" onClick={e=>updateState("username", e.target.value)}/>
                <div>
                    <button type="submit">Register</button>
                </div>
            </form>
            <div className="center">
                Wait, I have account, <Link to="/" className="link">Log me in</Link>.
            </div>
        </div>
        
    )
}

export default Register;