import { useQuery, useMutation, gql } from "@apollo/client";
import { redirect, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { FaPlus, FaCloudUploadAlt, FaUpload, FaPencilAlt } from "react-icons/fa";
import Items from "./BookList";
import Queries from "./queries";
import $ from "jquery";
import axios from "axios"
import Constants from "../constants";
import Session from "./Session";
import { Loading } from "notiflix";
import ReactLoading from "react-loading";



const 

books_       = Queries.getBooks(),
genres_      = Queries.categories(),
genred       = Queries.readGenred(),
users_       = Queries.users(),
byUser       = Queries.distinctBooks(),
add          = Queries.addBook();

function BookForm(props) {
    //console.log(props)
    const [state, setState] = useState({name: "", category: "", author: ""})
    const navigate = useNavigate(), location = useLocation();

    const [addBook, {data1, loading1, error1}] = useMutation(add, 
        {
            refetchQueries: [{
                query: books_
            },
            {
                query: byUser
            },
            {
                query: genres_
            },
            {
                query: genred
            }],
            variables: state
    })

    const myText = "Recently Added";
    var c = 0, times = 0;

    const delay = (callback, ms) => new Promise(resolve => setTimeout(callback, ms))
    

    async function doText(){
        
        if( times < 3) times++;
        if( c >= myText.length || 
            myText.toLowerCase().substring(0, c) !== $("#iter").text().toLowerCase().substring(0, c) ||
            myText.toLowerCase() === $("#iter").text().toLowerCase()) {
            
            //return clearInterval(doText);
            await delay(() => {
                if (c === 0 && $("#iter").text() !== "")
                    $("#iter").text("")
            }, 400)
            c = 0;
        }

        if (c === 0 && $("#iter").text() !== "") return clearInterval(doText);
        
        if( c < myText.length && $("#iter").text().toLowerCase() !== myText.toLowerCase()) {
           
            if($("#iter").text()[c] !== myText[c]) {
                $("#iter").append(myText[c])
                c += 1;
            }
        }
    }
    
    //setInterval(doText, 200)

    function getIcon(icon) {
        return (
            icon
        )
    }

    function useSubmit(e) {
        e.preventDefault()
        const upload = new FormData()
        
        if ( ! $(".form-data").is(":visible")) {
            $("#booklist").toggle()
            $("#add").toggle()
            $("#upload").toggle()

            return $(".form-data").show(250).fadeIn()
        } else {
    
            if (state.author === "") return false;

            //$(".upload").show()
            
            if (Object.keys(state).includes("file")) {

                var ext   = state.file.name.toLowerCase().split(".").pop()

                if (!["doc", "docx", "csv", "pdf", "txt", "ppt", "pptx", "xls", "xlsx"].includes(ext))
                    return Constants.tell(
                        "Invalid Document", 
                        "Only DOC, CSV, PDF, PPT, Excel and Text files are allowed!",
                        "warn"
                    )

                Loading.pulse()

                upload.append("myFile", state.file, state.file.name)
                axios.post(Constants.uploads, upload, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((resp) => {
                    
                    if(typeof resp.data === "object" && Object.keys(resp.data).includes("file")) {
                        var _bk = {
                            name: state.name,
                            category: state.category,
                            url: resp.data.file,
                            author: state.author,
                            size: state.file.size,
                            sizeDesc: Constants.formatSize(state.file.size, true),
                            user: Session.userID()
                        }

                        const {book} = addBook({
                            variables: _bk
                        })

                        
                        $("#add").toggle()
                        $("#upload").toggle()
                        $(".form-data").css({display: "none"})
                        $("input.form-data").val("")
                        Constants.tell("Document Added", "Your Document was uploaded successfully.")

                    }
                })
                .catch((e) => {
                    Loading.remove()
                    Constants.tell("Upload Failed!", "There was a problem uploading your file.", "warn")
                }).finally(() =>{
                    Loading.remove()
                    $("#booklist").toggle()
                })
            }
        }  
    }


    function updateState( key, value) {
        
        var file;

        if (key === "file") {
        if (! (typeof value === "object" && value.length > 0) || 
              (typeof value === "string" && value.trim() === "" )) 
                return console.log(value);
            file = typeof value === "object" && value.length > 0 ? value[0]: value;
            state["file"] = file

            return $("#chooser").text(`${
                file.name.length > 25 ? file.name.substring(0, 25) + "..." + Constants.getType(file.name) : file.name
                }
            `)
            //return navigate("/read", {state:{file: file}})
        }
        if (!Object.keys(state).includes(key)) return;
        state[key] = value

        setState(state)
    }

    const {loading, error, data} = useQuery(genres_)

    if (loading) return (
        <ReactLoading type="bubbles" height="50px" width="50px" color="#dedede"/>
    )

    if(error) return (
        <div>An error occured</div>
    )


    return (
        <form onSubmit={ useSubmit }>
            <legend className="form-data">Add New Book</legend>
            <div className="form-data" style={{textAlign: "right"}}>
                <button type="button" className="addit" onClick={e => navigate("/editor")}><FaPencilAlt/> New File</button>
            </div>
            <input className="form-data" placeholder="Title" name="name" onChange={(e) => updateState('name', e.target.value)}/>
            <input className="form-data" placeholder="Author" name="author" onChange={(e) => updateState('author', e.target.value)}/>
            <input type="file" name="file" accept=".doc, .docx, .csv, .pdf, .txt, .ppt, .pptx, .xls, .xlsx" id="book" onChange={
                (e) => updateState("file", e.target.files)
            } style={{display: "none"}}/>
            <div className="form-data" style={{display: "none", alignItems:"center", margin: "12.5px 0"}}>
                <button style={{
                    background: "#007185", 
                    color: "#ffffff", 
                    boxShadow: "2px 2px 2px #dedede",
                    marginLeft: "0",
                    borderRadius: "35px",
                    marginRight: "10px",
                    fontSize: "20px"}} onClick={e => $("#book").trigger("click")}>
                    <FaCloudUploadAlt />
                </button>
                <label htmlFor="book" id="chooser">Upload your document</label>
            </div>
            <div className="upload center">Uploading...</div>
            <select required="required" className="form-data" name="category" onChange={(e) => updateState("category", e.target.value)}>
                <option key="">- Category -</option>
                {
                    data.categories.map(({id, name}) => (
                        <option key={id} value={id}>{name}</option>
                    ))
                }
            </select>
            <div className='center'>
                <button type="submit" className="add" id="add"><FaPlus/> Add</button>
                <button type="submit" className="add" id="upload"><FaUpload/> Upload</button>
            </div>
        </form>
    );
  }
  
  // Export book
  export default BookForm;
  