import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading"
import { Link } from "react-router-dom";
import Queries from "./queries";
import 'reactjs-popup/dist/index.css';
import Constants from "../constants";
import Session from "./Session";



function Grid(props) {
  
  const location = useLocation()
  //const Special = location.pathname.length > 1 ? Queries.getBooks() : Special
  const cat      = getCat(location.pathname)
  var vars       = {variables: {sort: cat.toLowerCase().endsWith("sort")}}
  var Special    = Queries.getBooks()

  if (location.pathname.indexOf("!") >= 0) {
    Special     = Queries.booksByUser()
    vars["variables"]["user"] = cat
  }
  else if (! ["grid", "sort"].includes(cat.toLowerCase())) {
    Special = Queries.readGenred()
    vars["variables"]["category"] = cat
  } 
  else {
    vars["variables"]["max"] = "25";
  }
 
  const {loading, error, data} = useQuery(Special, vars)

  function getCat( path ) {
    return path.length > 1 && path.startsWith("/")? 
    path.split("/").pop().split("!").pop() : "" 
  }

  if (loading) return (
    <div className="specialGenre">
        <h2 className="center">Your Documents</h2>
        <ReactLoading type="bars" height="50px" width="50px" color="#dedede"/>
    </div>
   )

  if(error) {

    const message = error.name === "ApolloError" ? "Connection Problem" : "Data Load Failed."

    return (
    <div className="specialGenre">
        <h2 className="center">Your Books</h2>
        <div>{message}</div>
    </div>
    )
  }

  const first = (data.books || data.getFilteredBooks || data.booksByAuthor)[0].user.firstname;
  const books = (data.books || data.getFilteredBooks || data.booksByAuthor).filter(book => Session.canView(book))

  return (
    <div className="specialGenre gallery">
        <h2 className="center">Your Documents { 
            cat !== 'grid' && books.length > 0?
             <span>for {
                location.includes("!")? books[0].author.name:cat
            }</span>: "" 
        } ({books.length})</h2>
        
            {
                books.length > 0 ? 
                books.map(({id, name, category, url, author, user}) => (
                    <div className="shop-card" key={id}>
                      <Link to={{pathname: "/read"}} title={"Read " + name + " By " + user.firstname} 
                      state={{file: url}}
                      style={{display: "block"}}>
                        <img src={`/images/${Constants.getType(url)}.png`} width="100%" height="120" alt="PDF File"/> &nbsp;
                        {name.length > 10? name.substring(0, 10) + "..." : name}
                      </Link> 
                    </div>
                )) : (
                <div className="center">
                 
                 {
                    location.pathname.includes("!") && first.length? 
                    (<span><b>{first}</b> haven't shared documents with you.</span>) :
                    (<span>You haven't added <i>{cat}</i> documents yet.</span>)
                  }
                 
                 </div>
                )
            }
          <div className="spacer"></div>
    </div>
  );
}

// Export it
export default Grid;