import { useQuery, useMutation } from "@apollo/client";
import { FaTrash, FaCaretRight, FaFile, FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import BookForm from "./BookForm";
import Queries from "./queries";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Session from "./Session";
import Constants from "../constants";
import $ from "jquery";

const RemoveBook = Queries.removeBook()
function BookList(props) {

    const 
    
    // Set constants here
    loc         = useLocation(),
    BookQuery   = Queries.getBooks(),
    GenreQuery  = Queries.distinctBooks(),
    speak       = props.speak;

      if (Session.read())
      Constants.request(
        "Listen to voice welcome message", 
        (spk) => {
            if( spk ) {
                window.speechSynthesis.speak(speak)
                Session.setRead(false)
            }
        }, speak, "", true)

    var srt     = loc.pathname.endsWith("sort");

    const {loading, error, data} = useQuery(BookQuery, {
        variables: {
            sort: srt
        }
    }) 
      

    const [remove] = useMutation(RemoveBook, {
        refetchQueries: [{
            query: BookQuery
        },
        {
            query: GenreQuery
        }]
    })


    function del(item, element) {
        if (element)
        $(element).parentsUntil("div")
        .last().toggleClass("deleting");
        

        Constants.verify("Delete Book", 
        "Book deleted successfuly", 
        remove,{
            variables: {
                id: item.id
            }
        })
    }

    if (loading) return (
        <div className="automate">
            <h2 className="center">New Document</h2>
            <ReactLoading type="bars" height="50px" width="50px" color="#dedede"/>
        </div>
    )

    if(error) {
        const message = error.name === "ApolloError" ? "Connection Problem" : "Data Load Failed."
        toast(message)
        return (
            <div className="automate">
                <h2 className="center">New Documents</h2>
                <ToastContainer 
                position="center-center" 
                pauseOnHover="false"
                pauseOnFocusLoss="false"
                type="error"
                theme="colored"
                autoClose={500}
                />
                <div>{message}</div>
            </div>
        )
    }

    const books = data.books.filter(book => Session.canView(book, false, true));

    return (
        <div className={`automate ${Session.alone() ? "": "no-main"}`}>
            <div id="booklist">
              {books.length > 0 && <h2 className="center" id="iter">New Documents</h2>}
              <ul id="book-list">
                {
                books.length > 0 ? books.map(({id, name, category, url, author, sizeDesc, user}) => (
                    <li key={''+id} title={'By '.concat(user.firstname)}>
                        <div className="left">
                            <Link to={{pathname: "/read"}} state={{name: name, file: url}} className="flexible">
                            <img  alt="Cover" width='17' height='17' src={"/images/" + Constants.getType(url) + ".png"} /> 
                             {name.length > 20? name.substring(0, 20) + "..." : name}&nbsp;&nbsp;
                            </Link>
                            </div>
                        
                        <div className="right">
                            <span style={{color: "grey", fontSize: "14px", marginRight: "5px"}}>
                                {
                                    //Constants.getType(url, false, true)
                                    sizeDesc
                                }
                             </span>
                            <button style={{boxShadow: "none"}}className="danger delete" onClick={(e) => del({id}, e.target)}>
                                <FaTimes title="Delete" />
                            </button>
                        </div>
                    </li>
                )) : <div className="center">You haven't uploaded any documents yet.</div>}
               </ul>
               </div>
              <BookForm/>
            </div>
            );
  }
  
  export default BookList;
  