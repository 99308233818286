import { gql } from "@apollo/client";

class MyQueries {

    users() {
        return gql`
            query {
                users {
                    id
                    username
                    firstname
                    lastname
                    uploads {
                        name
                    }
                }
            }
            `
    }

    getBooks() {
        const q = gql`
            query ($max: String = "5", $sort: Boolean = false) {
                books(max: $max, sort: $sort){
                    id
                    name
                    category {
                        name
                    }
                    url
                    author
                    sizeDesc 
                    user {
                        id
                        firstname
                        lastname
                    }
                }
            }
        `
        return q;
    }



    getUsers() {
        const $q = gql`
            query{
                users {
                    id,
                    username,
                    firstname,
                    lastname,
                    email
                }
            }
        `
        return $q;
    }

    categories() {
        return gql`
        query {
            categories {
                id,
                name,
                books {
                    id
                    name
                }
            }
        }`
    }

    distinctBooks() {
        return gql`
        query {
            getBooks (distinct: "category"){
                id
                category {
                    name
                }
            }
        }
        `
    }

    readGenred() {
        return gql`
            query ( $category: String = "Education", $sort: Boolean = false) {
                getFilteredBooks( where: {category: $category}, sort: $sort) {
                    id
                    name
                    category {
                        name
                    }
                    url
                    author 
                    user {
                        id
                        firstname
                        lastname
                    }
                }
            }
        `
    }

    booksByUser() {
        return gql`
            query ($user: ID = "", $sort: Boolean = false) {
                booksByUser(user: $user, sort: $sort) {
                    id
                    name
                    category {
                        name
                    }
                    url
                    author
                    user {
                        id
                        firstname
                        lastname
                    }
                }
          }
          `
    }

    addBook() {
        //var defID   = Session.getUserKey('id')
        
        return gql`
            mutation ( 
                $name: String!, 
                $category: String!, 
                $url:String!, 
                $author: String, 
                $size: Int,
                $sizeDesc: String,
                $user: ID
            ) {
                addBook(
                    name: $name, 
                    category: $category, 
                    url: $url, 
                    author: $author,
                    size: $size,
                    sizeDesc: $sizeDesc, 
                    user: $user) {
                    name
                }
            }
        `
    }
    
    removeBook(){
        return gql`
            mutation ($id: String) {
                removeBook(id: $id) {
                    name
                }
            }
        `
    }
}

const Queries = new MyQueries()

export default Queries;