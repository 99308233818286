import DocViewer from "@cyntler/react-doc-viewer";
import { useLocation } from "react-router-dom";
import Constants from "../constants";
import Session from "./Session";

function BookReader (props) {

    const docs = [
        {uri: ""},
    ]

    
    // var search = document.querySelector(".search"), file;
    const {state} = useLocation();

    if( state && Object.keys(state).includes("file") ) {
        var 
        name        = state.hasOwnProperty("name") ? state.name : "",
        file        = state.file;
        docs[0].uri = `${Constants.api}/uploads/books/${file}`

        if (!Constants.isImage(file))
        Session.addFavorite({name: name, url: file})
        // URL.createObjectURL(file)
    }

    return (
        
        <DocViewer 
        documents={docs} 
        style={{height: "95vh"}}
        theme={{
            primary: "#ffffff",
            disableThemeScrollbar: false,
        }}
        />
        
    )
}

export default BookReader;